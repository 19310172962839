import "./Footer.css";
const Footer = () => {
  return (
    <footer className="footer">
      <div>Developed by Name Surname</div>
      <div>2022</div>
    </footer>
  );
};
export default Footer;
